<template>
  <v-app-bar id="app-bar" app color="#4caf50" flat height="75" clipped-left clipped-right>
    <v-btn
      v-if="token"
      class="mr-3"
      depressed
      fab
      small
      @click="setDrawer(!drawer)"
      color="transparent"
    >
      <!-- <v-icon v-if="drawer" large color="white"> mdi-close </v-icon>

      <v-icon v-else large color="white"> mdi-menu </v-icon> -->
      <img
          src="@/assets/images/logo_white_house.svg"
          width="50"
          height="50" />
    </v-btn>
    <v-spacer />
    <v-toolbar-title
      class="hidden-sm-and-down text-h3 font-weight-medium"
      v-text="title"
    />

    <v-spacer />

    <v-btn class="ml-2 text-h4" min-width="0" text v-if="token.length == 0" @click="signUp">
      <v-icon>mdi-account-plus</v-icon>{{ $t('common.signup') }}
    </v-btn>
    <v-btn class="ml-2 text-h4" min-width="0" text v-if="token.length == 0" @click="login">
      <v-icon>mdi-account</v-icon>{{ $t('common.login') }}
    </v-btn>
    <template v-else>
      <!-- <v-spacer></v-spacer> -->
      <a :href="publicPath+'media/計票系統操作手冊.pdf'" download>
        <v-btn class="ml-2 text-h4" min-width="0" text>
          <v-icon>mdi-download</v-icon>{{ $t("user.manual") }}
        </v-btn>
      </a>
      <v-badge
          color="red"
          :content="shoppingItems.length.toString()"
          offset-x="10"
          offset-y="10"
        >
          <v-btn
            depressed
            fab
            small
            class="mr-0 text-h5"
            color="transparent"
            @click="SET_SHOPPING_CART(!shoppingCart)"
          >
            <v-icon large color="white"> mdi-cart-variant </v-icon>
          </v-btn>
      </v-badge>
      <v-btn class="ml-2 text-h4" min-width="0" text @click="logout">
        <v-icon>mdi-logout</v-icon>{{ $t('common.logout') }}
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations, mapActions } from "vuex";
import { UserRole } from '@/definition'

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    publicPath: process.env.BASE_URL,
    UserRole: UserRole,
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one",
    ],
  }),

  computed: {
    ...mapState(["drawer", "shoppingCart", "shoppingItems", "username", "token", "title", "role"]),
  },

  methods: {
    ...mapActions(["sumbitToken"]),
    ...mapMutations({
      setDrawer: "SET_DRAWER",
      SET_SHOPPING_CART: "SET_SHOPPING_CART",
    }),
    logout() {
      this.sumbitToken({
        username: "",
        token: "",
        role: UserRole.Unpaid,
        expiredTime: "",
      });
      this.$router.push("/login").catch(err => {})
    },
    login() {
      if(this.$route.name !== 'Login'){
        this.$router.push("/login")
      }
    },
    signUp() {
      if(this.$route.name !== 'SignUp'){
        this.$router.push("/signup")
      }
    },
  },
  watch: {
    token() {
      if (!this.token) {
        this.$store.commit("SET_DRAWER", false);
      } else {
        this.$store.commit("SET_DRAWER", true);
      }
    },
  },
};
</script>
<style lang="sass" scope>
.v-app-bar
  background: linear-gradient(to left, #73BB28, #2DA533)
.v-toolbar__title
  color: white
.v-toolbar__content
  .v-btn__content
    color: white
</style>